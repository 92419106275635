<h5 class="mt-3">
    <span i18n="@@m3Info_label" app-i18n="m3Info_label">Geschat volume inboedel</span>

    <div class="btn btn-sm btn-link float-right" (click)="fromClipboard()" *ngIf="admin">
        <fa-icon [icon]="['fal', 'clipboard']" [fixedWidth]="true"></fa-icon>
    </div>
</h5>
<div class="card bg-light">
    <div class="card-body text-center">
        <fa-icon class="mt-2" size="2x" [icon]="['fal', 'box-open']" [fixedWidth]="true"></fa-icon>
        <h4 class="font-weight-bold mt-2">{{ form.value.total | number }} m<sup>3</sup></h4>
    </div>
    <div class="card-body pt-0" style="height: 448px; overflow-y: scroll" *ngIf="listItems">
        <h5 class="text-center" *ngIf="form.value.total === 0" i18n="@@m3Info_noObjects" app-i18n="m3Info_noObjects">
            Geen objecten gekozen
        </h5>
        <ng-container *ngFor="let category of m3Object">
            <strong *ngIf="form.get(category.categoryName + '.total').value">{{ category.categoryTitle }}</strong>
            <br />
            <small
                [class.d-none]="form.get(category.categoryName + '.' + object.objectName).value === 0 ? true : null"
                [style.line-height]="1"
                class="form-text text-muted"
                *ngFor="let object of category.objects"
            >
                {{ object.objectTitle }}: {{ form.get(category.categoryName + '.' + object.objectName).value }} <br />
            </small>
        </ng-container>
    </div>
</div>
